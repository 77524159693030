import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import {api} from "../../utils/http";
import {toast} from "react-toastify";
import {
  addFirstSitting,
  addSecondSitting,
  addUploads
} from "../../redux/reducers/features/applicant-data/ssce-info-slice";

const examBody = ["WAEC", "NECO", "NABTEB"];

const subjects = [
    "COMMERCE",
    "FINANCIAL ACCOUNTING",
    "CHRISTIAN RELIGIOUS STUDIES",
    "ECONOMICS",
    "GEOGRAPHY",
    "GOVERNMENT",
    "ISLAMIC STUDIES",
    "LITERATURE IN ENGLISH",
    "CIVIC EDUCATION",
    "ENGLISH LANGUAGE",
    "HAUSA",
    "IGBO",
    "YORUBA",
    "FURTHER MATHEMATICS",
    "GENERAL MATHEMATICS",
    "AGRICULTURAL SCIENCE",
    "BIOLOGY",
    "CHEMISTRY",
    "PHYSICS",
    "French",
    "Fishery",
    "Computer Studies"
  ],
  grades = ["A1", "B2", "B3", "C4", "C5", "C6", "D7", "E8", "F9"],
  uploads = [
    { id: "utme_slip", name: "JAMB UTME Exam Result" },
    { id: "bank_slip", name: "Bank Slip Proof of Payment" },
    {
      id: "sse_result",
      name: "Entry Qualification Certificate (WASSCE, NECO, NABTEB)",
    },
    { id: "passport_photo", name: "Recent Passport Sized Photo" },
    { id: "lg_origin", name: "Local Govt Identification Letter" },
    { id: "stamped_envelop", name: "Self Stamped Envelop" },
    {
      id: "nin_slip",
      name: "Scanned National Identification Number Slip/Card",
    },
    { id: "birth_cert", name: "Birth Certificate" },
  ];

export default function SsceResultAndFiles({ onPreviousClick, formInputs }) {
  const dispatch = useDispatch();
  const [examType1, setExamType1] = React.useState(""),
    [firstSubject1, setFirstSubject1] = React.useState("ENGLISH LANGUAGE"),
    [firstSubject2, setFirstSubject2] = React.useState("GENERAL MATHEMATICS"),
    [firstSubject3, setFirstSubject3] = React.useState(""),
    [firstSubject4, setFirstSubject4] = React.useState(""),
    [firstSubject5, setFirstSubject5] = React.useState(""),
    [firstSubject6, setFirstSubject6] = React.useState(""),
    [firstSubject7, setFirstSubject7] = React.useState(""),
    [firstSubject8, setFirstSubject8] = React.useState(""),
    [firstSubject9, setFirstSubject9] = React.useState(""),
    [firstGrade1, setFirstGrade1] = React.useState(""),
    [firstGrade2, setFirstGrade2] = React.useState(""),
    [firstGrade3, setFirstGrade3] = React.useState(""),
    [firstGrade4, setFirstGrade4] = React.useState(""),
    [firstGrade5, setFirstGrade5] = React.useState(""),
    [firstGrade6, setFirstGrade6] = React.useState(""),
    [firstGrade7, setFirstGrade7] = React.useState(""),
    [firstGrade8, setFirstGrade8] = React.useState(""),
    [firstGrade9, setFirstGrade9] = React.useState(""),
    [examType2, setExamType2] = React.useState(""),
    [secondSubject1, setSecondSubject1] = React.useState(""),
    [secondSubject2, setSecondSubject2] = React.useState(""),
    [secondSubject3, setSecondSubject3] = React.useState(""),
    [secondSubject4, setSecondSubject4] = React.useState(""),
    [secondSubject5, setSecondSubject5] = React.useState(""),
    [secondSubject6, setSecondSubject6] = React.useState(""),
    [secondSubject7, setSecondSubject7] = React.useState(""),
    [secondSubject8, setSecondSubject8] = React.useState(""),
    [secondSubject9, setSecondSubject9] = React.useState(""),
    [secondGrade1, setSecondGrade1] = React.useState(""),
    [secondGrade2, setSecondGrade2] = React.useState(""),
    [secondGrade3, setSecondGrade3] = React.useState(""),
    [secondGrade4, setSecondGrade4] = React.useState(""),
    [secondGrade5, setSecondGrade5] = React.useState(""),
    [secondGrade6, setSecondGrade6] = React.useState(""),
    [secondGrade7, setSecondGrade7] = React.useState(""),
    [secondGrade8, setSecondGrade8] = React.useState(""),
    [secondGrade9, setSecondGrade9] = React.useState(""),
    [showSecondSitting, setShowSecondSitting] = React.useState(false),
    [isSubmitting, setIsSubmitting] = React.useState(false),
    [attestation, setAttestation] = React.useState(false), //required
    [openDialog, setOpenDialog] = React.useState(false), //required
    [otherInputs, setOtherInputs] = React.useState({}),
    [uploadList, setUploadList] = React.useState([]); //required

  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async () => {
    if (attestation) {
      setIsSubmitting(true);
      try {
        if (uploadList && uploadList.length > 5) {
          let uploadedDoc = [];
          for (let index = 0; index < uploadList.length; index++) {
            const formData = new FormData();
            formData.append("file", uploadList[index].file);
            let response = await api.post(
              "/api/student-documents/upload-file",
              formData
            );
            let object = {
              file_url: response.data.url,
              file_name: response.data.filename,
              file_size: response.data.size,
              upload_type: uploadList[index].id,
            };
            uploadedDoc.push(object);
          }

          console.log("uploaded docs", uploadedDoc);

          let data = {
            exam_first_sitting: {
              exam_type: examType1,
              subject_grade: [
                { subject: firstSubject1, grade: firstGrade1 },
                { subject: firstSubject2, grade: firstGrade2 },
                { subject: firstSubject3, grade: firstGrade3 },
                { subject: firstSubject4, grade: firstGrade4 },
                { subject: firstSubject5, grade: firstGrade5 },
                { subject: firstSubject6, grade: firstGrade6 },
                { subject: firstSubject7, grade: firstGrade7 },
                { subject: firstSubject8, grade: firstGrade8 },
                { subject: firstSubject9, grade: firstGrade9 },
              ],
            },

            exam_second_sitting: {
              exam_type: examType2,
              subject_grade: [
                { subject: secondSubject1, grade: secondGrade1 },
                { subject: secondSubject2, grade: secondGrade2 },
                { subject: secondSubject3, grade: secondGrade3 },
                { subject: secondSubject4, grade: secondGrade4 },
                { subject: secondSubject5, grade: secondGrade5 },
                { subject: secondSubject6, grade: secondGrade6 },
                { subject: secondSubject7, grade: secondGrade7 },
                { subject: secondSubject8, grade: secondGrade8 },
                { subject: secondSubject9, grade: secondGrade9 },
              ],
            },

            documents: uploadedDoc,
          };

          let _data = { ...data, ...otherInputs };

          if (uploadedDoc && uploadedDoc.length > 4) {
            _data.docoments = uploadedDoc;

            const responseApi = await api.post("/api/applicant/create", _data);

            if (responseApi.success) {
              localStorage.setItem('applicant_id', responseApi.data._id)
              dispatch(addUploads([...responseApi.data?.documents]))
              dispatch(addFirstSitting(data.exam_first_sitting))
              dispatch(addSecondSitting(data.exam_second_sitting))
              toast.success("application successful", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setOpenDialog(false);
              navigate("/application-summary");
            } else {
              toast.error("application was not successful", {
                position: "top-right",
              });
            }
          } else {
            toast("unable to upload documents", { position: "top-right" });
          }
        } else {
          toast("please upload all required documents", {
            position: "top-right",
          });
        }
      } catch (e) {
        toast.error(e.message, { position: "top-right" });
      }
      setIsSubmitting(false);
    } else {
      toast.error("ensure all required fields are filled", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleFilesUpload = async (event, type) => {
    if (event.target.files) {
      // setUpload(event.target.files[0]);
      console.log(uploadList,"===>")
      let newList = uploadList.filter((item) => item.id !== type.id);
      setUploadList(newList);
      const doc = { id: type.id, file: event.target.files[0] };
      setUploadList((current) => [...current, doc]);
    }
  };

  const getFileName = (type) => {
    let file = uploadList.find((item) => item.id === type.id);
    if (file && file.file && file.file.name) return file.file.name;
    return null;
  };

  React.useEffect(() => {
    setOtherInputs(formInputs);
  }, [showSecondSitting, uploadList, formInputs]);

  return (
    <React.Fragment>
      <Card sx={{ p: { xs: 0, lg: 3 }, mt: { xs: 1.8, lg: 3 } }}>
        <CardContent>
          <Typography variant="h5" className="fw-bold">
            Senior Secondary School Certificate Details
          </Typography>

          <Typography variant="body1" className="fw-bold mt-4">
            Details of O'level (Not more than two sittings) SSCE RESULTS (First
            Sitting)
          </Typography>
          <Grid container spacing={2} sx={{ mt: 0.3 }}>
            <Grid item xs={12} lg={12}>
              <Grid
                container
                spacing={1}
                sx={{
                  mt: 0.5,
                  border: 1,
                  p: 1.2,
                  borderColor: "primary.main",
                }}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <InputLabel>Select Exam Type</InputLabel>
                  <Select
                    value={examType1}
                    onChange={(e) => setExamType1(e.target.value)}
                    fullWidth
                    label="Select exam type"
                  >
                    {examBody.map((body, index) => (
                      <MenuItem value={body} key={index}>
                        {body}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                spacing={1}
                sx={{ mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main" }}
              >
                <Grid item xs={12} lg={8}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Subject</InputLabel>
                    <Select
                      value={firstSubject1}
                      onChange={(e) => setFirstSubject1(e.target.value)}
                      fullWidth
                      label="Select Subject"
                    >
                      {subjects.map((subject, index) => (
                        <MenuItem
                          value={subject}
                          key={Math.random() + "-" + index}
                        >
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Grade</InputLabel>
                    <Select
                      value={firstGrade1}
                      onChange={(e) => setFirstGrade1(e.target.value)}
                      fullWidth
                      label="Select Grade"
                    >
                      {grades.map((grade, index) => (
                        <MenuItem
                          value={grade}
                          key={Math.random() + "-" + index}
                        >
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                spacing={1}
                sx={{ mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main" }}
              >
                <Grid item xs={12} lg={8}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Subject</InputLabel>
                    <Select
                      value={firstSubject2}
                      onChange={(e) => setFirstSubject2(e.target.value)}
                      fullWidth
                      label="Select Subject"
                    >
                      {subjects.map((subject, index) => (
                        <MenuItem
                          value={subject}
                          key={Math.random() + "-" + index}
                        >
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Grade</InputLabel>
                    <Select
                      value={firstGrade2}
                      onChange={(e) => setFirstGrade2(e.target.value)}
                      fullWidth
                      label="Select Grade"
                    >
                      {grades.map((grade, index) => (
                        <MenuItem
                          value={grade}
                          key={Math.random() + "-" + index}
                        >
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*/End of Row One*/}

          {/*Start of Row Two*/}
          <Grid container spacing={2} sx={{ mt: 0.3 }}>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                spacing={1}
                sx={{ mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main" }}
              >
                <Grid item xs={12} lg={8}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Subject</InputLabel>
                    <Select
                      value={firstSubject3}
                      onChange={(e) => setFirstSubject3(e.target.value)}
                      fullWidth
                      label="Select Subject"
                    >
                      {subjects.map((subject, index) => (
                        <MenuItem
                          value={subject}
                          key={Math.random() + "-" + index}
                        >
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Grade</InputLabel>
                    <Select
                      value={firstGrade3}
                      onChange={(e) => setFirstGrade3(e.target.value)}
                      fullWidth
                      label="Select Grade"
                    >
                      {grades.map((grade, index) => (
                        <MenuItem
                          value={grade}
                          key={Math.random() + "-" + index}
                        >
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                spacing={1}
                sx={{ mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main" }}
              >
                <Grid item xs={12} lg={8}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Subject</InputLabel>
                    <Select
                      value={firstSubject4}
                      onChange={(e) => setFirstSubject4(e.target.value)}
                      fullWidth
                      label="Select Subject"
                    >
                      {subjects.map((subject, index) => (
                        <MenuItem
                          value={subject}
                          key={Math.random() + "-" + index}
                        >
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Grade</InputLabel>
                    <Select
                      value={firstGrade4}
                      onChange={(e) => setFirstGrade4(e.target.value)}
                      fullWidth
                      label="Select Grade"
                    >
                      {grades.map((grade, index) => (
                        <MenuItem
                          value={grade}
                          key={Math.random() + "-" + index}
                        >
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*/End of Row Two*/}

          {/*Start of Row Three*/}
          <Grid container spacing={2} sx={{ mt: 0.3 }}>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                spacing={1}
                sx={{ mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main" }}
              >
                <Grid item xs={12} lg={8}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Subject</InputLabel>
                    <Select
                      value={firstSubject5}
                      onChange={(e) => setFirstSubject5(e.target.value)}
                      fullWidth
                      label="Select Subject"
                    >
                      {subjects.map((subject, index) => (
                        <MenuItem
                          value={subject}
                          key={Math.random() + "-" + index}
                        >
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Grade</InputLabel>
                    <Select
                      value={firstGrade5}
                      onChange={(e) => setFirstGrade5(e.target.value)}
                      fullWidth
                      label="Select Grade"
                    >
                      {grades.map((grade, index) => (
                        <MenuItem
                          value={grade}
                          key={Math.random() + "-" + index}
                        >
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                spacing={1}
                sx={{ mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main" }}
              >
                <Grid item xs={12} lg={8}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Subject</InputLabel>
                    <Select
                      value={firstSubject6}
                      onChange={(e) => setFirstSubject6(e.target.value)}
                      fullWidth
                      label="Select Subject"
                    >
                      {subjects.map((subject, index) => (
                        <MenuItem
                          value={subject}
                          key={Math.random() + "-" + index}
                        >
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Grade</InputLabel>
                    <Select
                      value={firstGrade6}
                      onChange={(e) => setFirstGrade6(e.target.value)}
                      fullWidth
                      label="Select Grade"
                    >
                      {grades.map((grade, index) => (
                        <MenuItem
                          value={grade}
                          key={Math.random() + "-" + index}
                        >
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*/End of Row Three*/}

          {/*Start of Row Four*/}
          <Grid container spacing={2} sx={{ mt: 0.3 }}>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                spacing={1}
                sx={{ mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main" }}
              >
                <Grid item xs={12} lg={8}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Subject</InputLabel>
                    <Select
                      value={firstSubject7}
                      onChange={(e) => setFirstSubject7(e.target.value)}
                      fullWidth
                      label="Select Subject"
                    >
                      {subjects.map((subject, index) => (
                        <MenuItem
                          value={subject}
                          key={Math.random() + "-" + index}
                        >
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Grade</InputLabel>
                    <Select
                      value={firstGrade7}
                      onChange={(e) => setFirstGrade7(e.target.value)}
                      fullWidth
                      label="Select Grade"
                    >
                      {grades.map((grade, index) => (
                        <MenuItem
                          value={grade}
                          key={Math.random() + "-" + index}
                        >
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                spacing={1}
                sx={{ mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main" }}
              >
                <Grid item xs={12} lg={8}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Subject</InputLabel>
                    <Select
                      value={firstSubject8}
                      onChange={(e) => setFirstSubject8(e.target.value)}
                      fullWidth
                      label="Select Subject"
                    >
                      {subjects.map((subject, index) => (
                        <MenuItem
                          value={subject}
                          key={Math.random() + "-" + index}
                        >
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Grade</InputLabel>
                    <Select
                      value={firstGrade8}
                      onChange={(e) => setFirstGrade8(e.target.value)}
                      fullWidth
                      label="Select Grade"
                    >
                      {grades.map((grade, index) => (
                        <MenuItem
                          value={grade}
                          key={Math.random() + "-" + index}
                        >
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*/End of Row Four*/}

          {/*Start of Row Five*/}
          <Grid container spacing={2} sx={{ mt: 0.3 }}>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                spacing={1}
                sx={{ mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main" }}
              >
                <Grid item xs={12} lg={8}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Subject</InputLabel>
                    <Select
                      value={firstSubject9}
                      onChange={(e) => setFirstSubject9(e.target.value)}
                      fullWidth
                      label="Select Subject"
                    >
                      {subjects.map((subject, index) => (
                        <MenuItem
                          value={subject}
                          key={Math.random() + "-" + index}
                        >
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel>Select Grade</InputLabel>
                    <Select
                      value={firstGrade9}
                      onChange={(e) => setFirstGrade9(e.target.value)}
                      fullWidth
                      label="Select Grade"
                    >
                      {grades.map((grade, index) => (
                        <MenuItem
                          value={grade}
                          key={Math.random() + "-" + index}
                        >
                          {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*/End of Row Five*/}

          <FormControlLabel
            sx={{ mt: 3 }}
            control={
              <Checkbox
                checked={showSecondSitting}
                onChange={(e) => setShowSecondSitting(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Second Sitting?"
          />

          {showSecondSitting && (
            <React.Fragment>
              <Typography variant="body1" className="fw-bold mt-2">
                Details of O'level SSCE RESULTS (Second Sitting)
              </Typography>

              {/*Start of Row One*/}
              <Grid container spacing={2} sx={{ mt: 0.3 }}>
                <Grid item xs={12} lg={12}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <FormControl sx={{ minWidth: "100%" }}>
                      <InputLabel>Select Exam Type</InputLabel>
                      <Select
                        value={examType2}
                        onChange={(e) => setExamType2(e.target.value)}
                        fullWidth
                        label="Select exam type"
                      >
                        {examBody.map((body, index) => (
                          <MenuItem value={body} key={index}>
                            {body}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject1}
                          onChange={(e) => setSecondSubject1(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade1}
                          onChange={(e) => setSecondGrade1(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject2}
                          onChange={(e) => setSecondSubject2(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade2}
                          onChange={(e) => setSecondGrade2(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*/End of Row One*/}

              {/*Start of Row Two*/}
              <Grid container spacing={2} sx={{ mt: 0.3 }}>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject3}
                          onChange={(e) => setSecondSubject3(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade3}
                          onChange={(e) => setSecondGrade3(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject4}
                          onChange={(e) => setSecondSubject4(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade4}
                          onChange={(e) => setSecondGrade4(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*/End of Row Two*/}

              {/*Start of Row Three*/}
              <Grid container spacing={2} sx={{ mt: 0.3 }}>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject5}
                          onChange={(e) => setSecondSubject5(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade5}
                          onChange={(e) => setSecondGrade5(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject6}
                          onChange={(e) => setSecondSubject6(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade6}
                          onChange={(e) => setSecondGrade6(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*/End of Row Three*/}

              {/*Start of Row Four*/}
              <Grid container spacing={2} sx={{ mt: 0.3 }}>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject7}
                          onChange={(e) => setSecondSubject7(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade7}
                          onChange={(e) => setSecondGrade7(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject8}
                          onChange={(e) => setSecondSubject8(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade8}
                          onChange={(e) => setSecondGrade8(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*/End of Row Four*/}

              {/*Start of Row Five*/}
              <Grid container spacing={2} sx={{ mt: 0.3 }}>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject9}
                          onChange={(e) => setSecondSubject9(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade9}
                          onChange={(e) => setSecondGrade9(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*/End of Row One*/}
            </React.Fragment>
          )}

          <Typography variant="body1" className="fw-bold my-2">
            Upload Documents (attach copied of these documents)
          </Typography>
          <Grid container spacing={2}>
            {uploads.map((row, index) => (
              <Grid
                item
                xs={12}
                lg={6}
                key={`${Math.random()}-${index}`}
                sx={{ width: "45%" }}
              >
                <Typography component={"div"} variant="body2">
                  {row.name}
                </Typography>
                <label
                  htmlFor={`upload-image-${row.id}`}
                  style={{ width: "100%", marginTop: "-13px" }}
                >
                  <Button
                    variant="outlined"
                    component="span"
                    fullWidth
                    sx={{
                      py: 1.5,
                      mt: 2,
                      textTransform: "lowercase",
                      borderRadius: 2,
                      color: "#000",
                    }}
                  >
                    {getFileName(row) ? getFileName(row) : row.name}
                  </Button>
                  <input
                    id={`upload-image-${row.id}`}
                    hidden
                    accept="image/*,application/pdf"
                    type="file"
                    onChange={(e) => handleFilesUpload(e, row)}
                  />
                </label>
              </Grid>
            ))}
          </Grid>

          <Typography variant="body1" className="fw-bold mt-3">
            Attestation
          </Typography>
          <FormControlLabel
            sx={{ mt: 0 }}
            control={
              <Checkbox
                checked={attestation}
                onChange={(e) => setAttestation(e.target.checked)}
                inputProps={{ "aria-label": "controlled_1" }}
              />
            }
            label="I hereby confess that the above information is correct to the best of my knowledge. If any of the information is proved to be false, I will be considered disqualified."
          />
        </CardContent>

        <CardActions>
          <Grid container spacing={2} className="px-2">
            <Grid item xs={6} lg={6}>
              <Button
                variant="outlined"
                color="tertiary"
                fullWidth
                onClick={onPreviousClick}
                sx={{
                  py: 1.5,
                  mt: 2,
                  textTransform: "uppercase",
                  borderRadius: 2,
                  color: "#000",
                }}
              >
                Previous
              </Button>
            </Grid>

            <Grid item xs={6} lg={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setOpenDialog(true)}
                sx={{
                  py: 1.5,
                  mt: 2,
                  textTransform: "uppercase",
                  borderRadius: 2,
                  color: "#fff",
                }}
              >
                Proceed
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Attention"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please, Note that candidates are expected to pay a non-refundable
            processing fee of ₦3,000 for those with one O' Level sitting and
            ₦5,000 for those with two O' Level sittings. Failure of payment is
            an automatic disqualification.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDialog}>
            Cancel Application
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            autoFocus
            endIcon={
              isSubmitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#ec7ebd",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                ""
              )
            }
          >
            Submit Application
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
