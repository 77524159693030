import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Routes from "./routes";
import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {BrowserRouter} from "react-router-dom";
import MasterLayout from "./layouts/master";
import {Setup} from "./utils/http";
import { ToastContainer } from 'react-toastify';

const theme = createTheme({
    palette: {
        primary: {
            main: '#39b54a',
        },
        secondary: {
            main: '#c1a13d',
        },
        tertiary: {
            main: '#000000',
        }
    },
    typography: {
        fontFamily: "roboto, sans-serif",
    }
});
Setup();
function App() {
  return (
      <BrowserRouter>
          <ThemeProvider theme={theme}>
              <MasterLayout>
                  <Routes/>
                  <ToastContainer/>
              </MasterLayout>
          </ThemeProvider>
      </BrowserRouter>
  );
}

export default App;
