import * as React from 'react';
import {Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux'
import '../../styles/dashbaord/dashboard-styles.css'
import StatusModal from './modal/StatusModal';
import axios from "axios";
import DrawerComponent from "./components/DrawerComponent";
import MyApplication from "./components/MyApplication";
import DashboardItem from "./components/Dashboard";
import PrivateRoutes from "./guards/PrivateRoutes";

function Dashboard(props) {
    const modalRef = React.useRef();
    const [applicantData, setApplicantData] = React.useState({});
    const applicantId = useSelector(state => state.auth).loginSuccess?.user_id
    React.useEffect(() => {
        (async function () {
            let applicantData = {};
            const _applicantId = localStorage.getItem('applicantId')
            try {
                if (process.env.NODE_ENV === 'production') {
                    applicantData = await axios.get(`https://api.portal.akum.edu.ng/api/applicant/${applicantId || _applicantId}`);
                    setApplicantData(applicantData.data.data)
                } else if (process.env.NODE_ENV === 'development') {
                    applicantData = await axios.get(`http://localhost:5200/api/applicant/${applicantId || _applicantId}`);
                    setApplicantData(applicantData.data.data);
                }
            } catch (e) {
                console.error("Error in Dashboard", e)
            }

            return () => {
                localStorage.removeItem('applicantId');

            }
        })()
        modalRef.current?.click();

    }, [])
    return (
        <div>
            <StatusModal
                admissionStatus={applicantData?.admission_status}
                ref={modalRef}
                lastName={applicantData?.last_name}
                firstName={applicantData?.first_name}
            />
            <Routes>
                <Route element={<PrivateRoutes/>}>
                <Route path="/" element={<DrawerComponent applicant={applicantData}/>}>
                    <Route index={true} element={<DashboardItem applicant={applicantData}/>}/>
                    <Route path="my-application" element={<MyApplication applicant={applicantData}/>}/>
                </Route>
                </Route>
            </Routes>
        </div>
    );
}

export default Dashboard;