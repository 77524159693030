import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logo from './../assets/img/akum-logo.png';

const pages = [
    {
        name: 'Prospective',
        url: '',
        id: 1
    },

    {
        name: 'Admission Process',
        url: '',
        id: 2
    },

    {
        name: 'Undergraduate',
        url: '',
        id: 3
    },
    {
        name: 'Check Admission Status',
        url: '/login',
        id: 4
    }
];

const headerBlackList = ['/login/','/dashboard/','/login','/dashboard' ]


export default function Header() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [showHeader, setShowHeader] = React.useState(true);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };


    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    React.useEffect(() => {

        (function () {
            const pathname = new URL(window.location.href).pathname;
            headerBlackList.forEach(item => {

                if (item === pathname) {
                    setShowHeader(false);
                }
            })

        })();

    }, [])

    return (
        <AppBar className={showHeader ? 'd-block' : 'd-none'} position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Avatar alt="Brand" src={logo} sx={{display: {xs: 'none', md: 'flex'}, mr: 1, height: '40px'}}/>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            flexGrow: 1,
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: '#ff2',
                            textDecoration: 'none',
                        }}
                    >
                        Abdulkadir Kure
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Avatar alt="Brand" src={logo} sx={{display: {xs: 'flex', md: 'none'}, mr: 1, height: '40px'}}/>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        AKUM
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (
                            <Button
                                key={page.id}
                                onClick={handleCloseNavMenu}
                                sx={{my: 2, color: 'white', display: 'block'}}
                                href={page.url}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{flexGrow: 0}}>
                        <Button
                            variant="contained"
                            color="warning"
                            href="/login"
                            sx={{
                                textTransform: 'capitalize',
                                px: 3
                            }}>
                            Login
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
