import React from "react";
import Header from "./Header";

export default function MasterLayout({children}) {
    return (
        <React.Fragment>
            <Header/>
            {children}
        </React.Fragment>
    )
}