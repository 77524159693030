import React, {useEffect, useState} from "react";
import {Container, CssBaseline, Grid, Typography} from "@mui/material";
import axios from "axios";
import "../../styles/document-to-print.css"
import logo from '../../assets/img/akum-logo.png'


const ComponentToPrint = React.forwardRef((props, ref) => {
    const [applicantData, setApplicantData] = useState({})
    const passport = applicantData?.documents?.find(value => value.upload_type === 'passport_photo');
    const umteSlip = applicantData?.documents?.find(value => value.upload_type === 'utme_slip');
    const bankSlip = applicantData?.documents?.find(value => value.upload_type === 'bank_slip');
    const ssceSlip = applicantData?.documents?.find(value => value.upload_type === 'sse_result');
    const lgaOriginSlip = applicantData?.documents?.find(value => value.upload_type === "lg_origin");
    const ninSlip = applicantData?.documents?.find(value => value.upload_type === 'nin_slip')
    const birthCertSlip = applicantData?.documents?.find(value => value.upload_type === 'birth_cert');

    const examGrade = (item, index) => (
        <li key={index}>
            {item.subject}- <span className="fw-bold">{item.grade}</span>
        </li>
    )

    useEffect(() => {
        (async function () {
            const applicantId = localStorage.getItem("applicant_id");
            let applicantData = {}
            if (process.env.NODE_ENV === 'production') {
                applicantData = await axios.get(`https://api.portal.akum.edu.ng/api/applicant/${applicantId}`)
            } else if (process.env.NODE_ENV === 'development') {
                applicantData = await axios.get(`http://localhost:5200/api/applicant/${applicantId}`)
            }
            setApplicantData(applicantData?.data?.data)
        })()

    }, []);

    return (
        <div ref={ref}>
            <CssBaseline/>
            <Container className="text-center my-3">

                   <img
                       src={logo}
                       height="100"
                       width="100"
                       alt="logo"
                   />

                <Typography variant="h3">
                    Abdulkadii Kure University
                </Typography>

                <Typography variant="h4" className="fw-bold text-success mt-5">
                    Application Slip
                </Typography>

                <Grid container spacing={2} className="mt-2">

                    <Grid item xs={12} lg={12}>
                        <div className="d-flex justify-content-between flex-wrap flex-lg-nowrap mt-3">

                            <div>
                                <Typography variant="h5" className="mb-4 fw-bolder">
                                    Applicants Biodata
                                </Typography>

                                <ul style={{listStyle: 'none', textAlign: 'left'}}>
                                    <li>Last Name: <span className="fw-bold">{applicantData?.last_name}</span></li>
                                    <li>Middle Name: <span className="fw-bold">{applicantData?.middle_name}</span></li>
                                    <li>First Name: <span className="fw-bold">{applicantData?.first_name}</span></li>
                                    <li>DOB: <span className="fw-bold">{new Date(applicantData?.dob).toLocaleDateString()}</span></li>
                                    <li>Email: <span className="fw-bold">{applicantData?.email}</span></li>
                                    <li>Gender: <span className="fw-bold">{applicantData?.gender}</span></li>
                                    <li>Country: <span className="fw-bold">{applicantData?.nationality}</span></li>
                                    <li>LGA: <span className="fw-bold">{applicantData?.lg}</span></li>
                                    <li>City: <span className="fw-bold">{applicantData?.city}</span></li>
                                    <li>Phone: <span className="fw-bold">{applicantData?.phone}</span></li>
                                </ul>
                            </div>
                            <div>
                                <img
                                    src={passport?.file_url}
                                    alt="Passport"
                                    style={{OObjectFit: 'cover', height: '150px%', width: '150px'}}
                                />

                            </div>

                        </div>
                        <hr/>
                    </Grid>


                    <Grid item xs={12} lg={12}>

                        <div className="d-flex justify-content-between flex-wrap flex-lg-nowrap">
                            <div>
                                <Typography variant="h5" className="mb-4 fw-bolder">
                                    First Choice
                                </Typography>

                                <ul style={{listStyle: 'none', textAlign: 'left'}}>
                                    <li>University: <span
                                        className="fw-bold">{applicantData?.jamb_first_choice?.university}</span>
                                    </li>
                                    <li>Polytechnic: <span
                                        className="fw-bold">{applicantData?.jamb_first_choice?.polytechnic}</span>
                                    </li>
                                    <li>Course: <span
                                        className="fw-bold">{applicantData?.jamb_first_choice?.course}</span></li>
                                </ul>
                            </div>
                            <div>
                                <Typography variant="h5" className="mb-4 fw-bolder">
                                    Second Choice
                                </Typography>

                                <ul className="text-start" style={{listStyle: 'none', textAlign: 'left'}}>
                                    <li>University: <span
                                        className="fw-bold">{applicantData?.jamb_second_choice?.university}</span>
                                    </li>
                                    <li>Polytechnic: <span
                                        className="fw-bold">{applicantData?.jamb_second_choice?.polytechnic}</span>
                                    </li>
                                    <li>Course: <span
                                        className="fw-bold">{applicantData?.jamb_second_choice?.course}</span></li>
                                </ul>
                            </div>
                        </div>
                        <hr/>
                    </Grid>

                    <Grid item xs={12} lg={12}>
                        <div className="d-flex justify-content-between flex-wrap flex-lg-nowrap">
                            <div>
                                <Typography variant="h5" className="mb-4 fw-bolder">
                                    SSCE-First Sitting-- {applicantData?.exam_first_sitting?.exam_type}
                                </Typography>

                                <ul style={{listStyle: 'none', textAlign: 'left'}}>
                                    {
                                        applicantData?.exam_first_sitting?.subject_grade?.map((item, index) => examGrade(item, index))
                                    }
                                </ul>
                            </div>
                            <div className={applicantData?.exam_second_sitting?.exam_type ? 'd-block' : 'd-none'}>
                                <div>
                                    <Typography variant="h5" className="mb-4 fw-bolder">
                                        SSCE-Second Sitting-- {applicantData?.exam_second_sitting?.exam_type}
                                    </Typography>

                                    <ul style={{listStyle: 'none', textAlign: 'left'}}>
                                        {
                                            applicantData?.exam_second_sitting?.subject_grade?.map((item, index) => examGrade(item, index))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr/>

                    </Grid>


                    <Grid item xs={12} lg={12}>

                        <div className="d-flex justify-content-between flex-wrap">
                            <div>
                                <Typography variant="h5" className="mb-4 fw-bolder text-start">
                                    Uploaded Documents
                                </Typography>


                                <div className="card-group">
                                    <div className="card m-1">
                                        <img
                                            className="card-img-top"
                                            src={umteSlip?.file_url}
                                            alt="UMTE Slipe"
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">UMTE Slip</h5>
                                        </div>
                                    </div>
                                    <div className="card m-1">
                                        <img
                                            className="card-img-top"
                                            src={bankSlip?.file_url}
                                            alt="Bank Slip"
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">Bank Slip</h5>
                                        </div>
                                    </div>
                                    <div className="card m-1">
                                        <img
                                            className="card-img-top"
                                            src={lgaOriginSlip?.file_url}
                                            alt="LGA Slip"
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">LGA Slip</h5>
                                        </div>
                                    </div>
                                    <div className="card m-1">
                                        <img
                                            className="card-img-top"
                                            src={ninSlip?.file_url}
                                            alt="NIN Slip"
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">NIN Slip</h5>
                                        </div>
                                    </div>
                                    <div className="card m-1">
                                        <img
                                            className="card-img-top"
                                            src={ssceSlip?.file_url}
                                            alt="SSCE Slip"
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">SSCE Slip</h5>
                                        </div>
                                    </div>
                                    <div className="card m-1">
                                        <img
                                            className="card-img-top"
                                            src={birthCertSlip?.file_url}
                                            alt="SSCE Slip"
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">Birth Certificate Slip</h5>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div>
                                <Typography variant="h5" className="mt-5  mb-3 fw-bolder text-start">
                                    Custodian Information
                                </Typography>
                                <ul style={{listStyle: 'none'}} className="text-start">
                                    <li className="text-start">Guardian name: <span
                                        className="fw-bold">{applicantData?.guardian?.name}</span>
                                    </li>
                                    <li className="text-start">Guardian Phone: <span
                                        className="fw-bold">{applicantData?.guardian?.phone}</span>
                                    </li>
                                    <li className="text-start">Guardian Address: <span
                                        className="fw-bold">{applicantData?.guardian?.address}</span>
                                    </li>
                                    <li className="text-start">Guardian Relationship: <span
                                        className="fw-bold">{applicantData?.guardian?.relationship}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </Grid>
                </Grid>

            </Container>
        </div>
    )

})

export default ComponentToPrint;