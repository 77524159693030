import React from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import {toast} from "react-toastify";
import {useDispatch} from 'react-redux'
import {
    addJambFirstChoice,
    addJambNumber,
    addJambSecondChoice,
    addJambSubjectScore,
    addTotalScore,
    appliedFirstJoice,
    appliedSecondJoice
} from "../../redux/reducers/features/applicant-data/jamb-info-slice"

const jamb_subjects = [
    'English Language',
    'Mathematics',
    'Fine Arts',
    'Music',
    'French',
    'Animal Husbandry',
    'Insurance',
    'Chemistry',
    'Physics',
    'Yoruba',
    'Biology',
    'Geography',
    'Literature in English',
    'Economics',
    'Commerce',
    'Accounts - Principles of Accounts',
    'Government',
    'Igbo',
    'Christian Religious Knowledge (CRK)',
    'Agricultural Science',
    'Islamic Religious Knowledge (IRK)',
    'History',
    'Civic Education',
    'Further Mathematics',
    'Arabic',
    'Home Economics',
    'Hausa',
    'Book Keeping',
    'Data Processing',
    'Catering Craft Practice',
    'Computer Studies',
    'Marketing',
    'Physical Education',
    'Office Practice',
    'Fishery',
];

const faculties = [
    {
        id: 1,
        name: 'Education',
        departments: ['Education Management', 'Guidance and Cognitive Science', 'Primary Education', 'Library and Information Science']
    },
    {
        id: 2,
        name: 'Technical and Vocational Education',
        departments: ['Agriculture Education', 'Building Technology', 'Wood Work Technology',
            'Metal Work Technology', 'Automobile Technology', 'Electrical/Electronics Technology', 'Entrepreneurship Education']
    },
    {
        id: 3,
        name: 'Arts and Social Science Education',
        departments: ['Economics', 'Sustainable Development Studies', 'Islamic Studies', 'Christian Religious Studies',
            'English Education', 'Social Studies and Civic Education', 'History and International Studies']
    },
    {
        id: 4,
        name: 'Science Education',
        departments: ['Mathematics', 'Computer Science', 'Chemistry', 'Biology', 'Physics', 'Integrated Science', 'Human Kinetics and Health Education']
    }
];
//
// const faculties = [
//     {
//         id: 1,
//         name: "Business and Social Science",
//         departments: [
//             "Accounting", "Economics", "Mass Communication", "International Relations", "Transport Management"
//         ],
//     },
//     {
//         id: 2,
//         name: "Health Sciences",
//         departments: [
//             "Pharmacy", "Physiology", "Medical Laboratory Science", "Nursing Science"
//         ],
//     },
//     {
//         id: 3,
//         name: "Computer and Engineering",
//         departments: [
//             "Information Technology", "Computer Science", "Cyber Security", "Software Engineering", "Civic Engineering",
//             "Electrical and Electronics Engineering", "Mechanical Engineering", "Mechatronics Engineering", "Computer Engineering"
//         ]
//     },
//     {
//         id: 4,
//         name: "Law",
//         departments: [
//             "Law"
//         ]
//     }
// ];

export default function JambInformation({onForwardClick, onPreviousClick}) {

    const dispatch = useDispatch();

    const [jambNumber, setJambNumber] = React.useState(''), //required
        [jambTotalScore, setJambTotalScore] = React.useState(''), //required
        [jambScoreOne, setJambScoreOne] = React.useState(''),
        [jambScoreTwo, setJambScoreTwo] = React.useState(''),
        [jambScoreThree, setJambScoreThree] = React.useState(''),
        [jambScoreFour, setJambScoreFour] = React.useState(''),
        [jambSubjectOne, setJambSubjectOne] = React.useState('English Language'),
        [jambSubjectTwo, setJambSubjectTwo] = React.useState(''),
        [jambSubjectThree, setJambSubjectThree] = React.useState(''),
        [jambSubjectFour, setJambSubjectFour] = React.useState(''),
        [firstChoiceUni, setFirstChoiceUni] = React.useState(''),
        [firstChoicePoly, setFirstChoicePoly] = React.useState(''),
        [firstChoiceCourse, setFirstChoiceCourse] = React.useState(''),
        [secondChoiceUni, setSecondChoiceUni] = React.useState(''),
        [secondChoicePoly, setSecondChoicePoly] = React.useState(''),
        [secondChoiceCourse, setSecondChoiceCourse] = React.useState(''),
        [firstFaculty, setFirstFaculty] = React.useState(''),
        [firstDepartment, setFirstDepartment] = React.useState(''),
        [firstDepartments, setFirstDepartments] = React.useState([]),
        [secondFaculty, setSecondFaculty] = React.useState(''),
        [secondDepartment, setSecondDepartment] = React.useState(''),
        [secondDepartments, setSecondDepartments] = React.useState([]);

    const handleForwardClick = () => {
        if(jambNumber && jambTotalScore && firstChoiceUni && firstChoicePoly && firstChoiceCourse
            && secondChoiceUni && secondChoicePoly && secondChoiceCourse && firstDepartment && firstFaculty
            && secondDepartment && secondFaculty && jambSubjectOne && jambScoreOne && jambSubjectTwo
            && jambScoreTwo && jambSubjectThree && jambScoreThree && jambSubjectFour && jambScoreFour) {
            let data = {
                jamb_number: jambNumber,
                jamb_total_score: jambTotalScore,
                jamb_subject_scores: [
                    { subject: jambSubjectOne, score: jambScoreOne },
                    { subject: jambSubjectTwo, score: jambScoreTwo },
                    { subject: jambSubjectThree, score: jambScoreThree },
                    { subject: jambSubjectFour, score: jambScoreFour },
                ],
                jamb_first_choice: {
                    university: firstChoiceUni,
                    polytechnic: firstChoicePoly,
                    course: firstChoiceCourse
                },
                jamb_second_choice: {
                    university: secondChoiceUni,
                    polytechnic: secondChoicePoly,
                    course: secondChoiceCourse
                },
                applied_first_choice: {
                    department: firstDepartment,
                    faculty: firstFaculty
                },
                applied_second_choice: {
                    department: secondDepartment,
                    faculty: secondFaculty
                }
            };
            onForwardClick(data);
            dispatch(appliedSecondJoice(data.applied_second_choice));
            dispatch(appliedFirstJoice(data.applied_first_choice));
            dispatch(addTotalScore(data.jamb_total_score));
            dispatch(addJambSubjectScore(data.jamb_subject_scores));
            dispatch(addJambFirstChoice(data.jamb_first_choice));
            dispatch(addJambSecondChoice(data.jamb_second_choice));
            dispatch(addJambNumber(data.jamb_total_score));
        }else {
            toast.error('ensure all required fields are filled', { position: toast.POSITION.TOP_RIGHT })
        }
    };

    const getFirstDepartments = () => {
        let faculty = faculties.find(item => item.name === firstFaculty);
        if (faculty) {
            setFirstDepartments(faculty?.departments);
        }
    };

    const getSecondDepartments = () => {
        let faculty = faculties.find(item => item.name === secondFaculty);
        if (faculty) {
            setSecondDepartments(faculty?.departments);
        }
    };

    React.useEffect(() => {
        getFirstDepartments();
        getSecondDepartments();
    }, [ firstFaculty, secondFaculty ]);

    return (
        <Card sx={{ p: {xs: 0, lg: 3}, mt: {xs: 1.8, lg: 3} }}>
            <CardContent>
                <Typography variant='h5' className='fw-bold'>JAMB Details</Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="JAMB Number"
                                placeholder="Enter JAMB Number"
                                variant="outlined"
                                value={jambNumber}
                                onChange={(e) => setJambNumber(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                type={'number'}
                                label="JAMB Score"
                                placeholder='Select Score'
                                variant="outlined"
                                value={jambTotalScore}
                                onChange={(e) => setJambTotalScore(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography variant='body1' className='fw-bold mt-4 mt-lg-4'>JAMB Subjects and Scores</Typography>

                 <Grid container spacing={2} sx={{mt: -1.0}}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{ minWidth: '100%' }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Select Subject</InputLabel>
                            <Select
                                value={jambSubjectOne}
                                onChange={(e) => setJambSubjectOne(e.target.value)}
                                fullWidth
                                disabled
                                label="Select Subject"
                            >
                                {
                                    jamb_subjects.map((subject, index) => (
                                        <MenuItem value={subject} key={Math.random()+'-'+index}>{subject}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                type={'number'}
                                label="Subject Score"
                                placeholder='Subject Score'
                                variant="outlined"
                                value={jambScoreOne}
                                onChange={(e) => setJambScoreOne(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                 <Grid container spacing={2} sx={{mt: 3.0}}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{ minWidth: '100%' }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Select Subject</InputLabel>
                            <Select
                                value={jambSubjectTwo}
                                onChange={(e) => setJambSubjectTwo(e.target.value)}
                                fullWidth
                                label="Select Subject"
                            >
                                {
                                    jamb_subjects.map((subject, index) => (
                                        <MenuItem value={subject} key={Math.random()+'-'+index}>{subject}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                type={'number'}
                                label="Subject Score"
                                placeholder='Subject Score'
                                variant="outlined"
                                value={jambScoreTwo}
                                onChange={(e) => setJambScoreTwo(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>


                 <Grid container spacing={2} sx={{mt: 3.0}}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{ minWidth: '100%' }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Select Subject</InputLabel>
                            <Select
                                value={jambSubjectThree}
                                onChange={(e) => setJambSubjectThree(e.target.value)}
                                fullWidth
                                label="Select Subject"
                            >
                                {
                                    jamb_subjects.map((subject, index) => (
                                        <MenuItem value={subject} key={Math.random()+'-'+index}>{subject}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                type={'number'}
                                label="Subject Score"
                                placeholder='Subject Score'
                                variant="outlined"
                                value={jambScoreThree}
                                onChange={(e) => setJambScoreThree(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>


                 <Grid container spacing={2} sx={{mt: 3.0}}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{ minWidth: '100%' }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Select Subject</InputLabel>
                            <Select
                                value={jambSubjectFour}
                                onChange={(e) => setJambSubjectFour(e.target.value)}
                                fullWidth
                                label="Select Subject"
                            >
                                {
                                    jamb_subjects.map((subject, index) => (
                                        <MenuItem value={subject} key={Math.random()+'-'+index}>{subject}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                type={'number'}
                                label="Subject Score"
                                placeholder='Subject Score'
                                variant="outlined"
                                value={jambScoreFour}
                                onChange={(e) => setJambScoreFour(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography variant='body1' className='fw-bold mt-4'>Institutions of choices as stated in your JAMB UTME application form</Typography>

                <Grid container spacing={2} sx={{mt: -1.0}}>
                    <Grid item xs={12} lg={4}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                label="First Choice University"
                                placeholder="Enter Uni First Choice"
                                variant="outlined"
                                value={firstChoiceUni}
                                onChange={(e) => setFirstChoiceUni(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                label="First Choice Polytechnic"
                                placeholder='Enter Poly First Choice'
                                variant="outlined"
                                value={firstChoicePoly}
                                onChange={(e) => setFirstChoicePoly(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                label="First Choice Course"
                                placeholder='Enter First Choice Course'
                                variant="outlined"
                                value={firstChoiceCourse}
                                onChange={(e) => setFirstChoiceCourse(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{mt: 3}}>
                    <Grid item xs={12} lg={4}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                label="Second Choice University"
                                placeholder="Enter Uni Second Choice"
                                variant="outlined"
                                value={secondChoiceUni}
                                onChange={(e) => setSecondChoiceUni(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                label="Second Choice Polytechnic"
                                placeholder='Enter Poly Second Choice'
                                variant="outlined"
                                value={secondChoicePoly}
                                onChange={(e) => setSecondChoicePoly(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                label="Second Choice Course"
                                placeholder='Enter Second Choice Course'
                                variant="outlined"
                                value={secondChoiceCourse}
                                onChange={(e) => setSecondChoiceCourse(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography variant='body1' className='fw-bold mt-4 mt-lg-4'>Desired Department and Faculty at Abdulkadir Kure University, Minna</Typography>

                <Grid container spacing={2} sx={{mt: -1.5}}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{ mt: 1, minWidth: '100%' }}>
                            <InputLabel>Select First Choice Faculty</InputLabel>
                            <Select
                                value={firstFaculty}
                                onChange={(e) => setFirstFaculty(e.target.value)}
                                fullWidth
                                label="First Choice Faculty"
                            >
                                {
                                    faculties.map((row, index) => (
                                        <MenuItem value={row.name} key={Math.random()+'-'+index}>{row.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{ mt: 1, minWidth: '100%' }}>
                            <InputLabel>Select First Choice Department</InputLabel>
                            <Select
                                value={firstDepartment}
                                onChange={(e) => setFirstDepartment(e.target.value)}
                                fullWidth
                                label="First Choice Department"
                            >
                                {
                                    firstDepartments.map((row, index) => (
                                        <MenuItem value={row} key={Math.random()+'-'+index}>{row}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{mt: 3}}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{ mt: 1, minWidth: '100%' }}>
                            <InputLabel>Select Second Choice Faculty</InputLabel>
                            <Select
                                value={secondFaculty}
                                onChange={(e) => setSecondFaculty(e.target.value)}
                                fullWidth
                                label="Second Choice Faculty"
                            >
                                {
                                    faculties.map((row, index) => (
                                        <MenuItem value={row.name} key={Math.random()+'-'+index}>{row.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{ mt: 1, minWidth: '100%' }}>
                            <InputLabel>Select Second Choice Department</InputLabel>
                            <Select
                                value={secondDepartment}
                                onChange={(e) => setSecondDepartment(e.target.value)}
                                fullWidth
                                label="Second Choice Department"
                            >
                                {
                                    secondDepartments.map((row, index) => (
                                        <MenuItem value={row} key={Math.random()+'-'+index}>{row}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

            </CardContent>

            <CardActions>
                <Grid container spacing={2} className="px-2">
                    <Grid item xs={6} lg={6}>
                        <Button
                            variant="outlined"
                            color="tertiary"
                            fullWidth
                            onClick={onPreviousClick}
                            sx={{py: 1.5, mt: 2, textTransform: "uppercase", borderRadius: 2, color: "#000"}}
                        >
                            Previous
                        </Button>
                    </Grid>

                    <Grid item xs={6} lg={6} >
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleForwardClick}
                            sx={{py: 1.5, mt: 2, textTransform: "uppercase", borderRadius: 2, color: "#fff"}}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}