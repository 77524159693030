import React, {useRef} from "react"
import {useReactToPrint} from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";


function SummaryPage(props) {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Application slip',
        pageStyle: ''
    });

    return (
        <div className="container-fluid mb-5 text-center">
            <ComponentToPrint ref={componentRef}/>
            <button
                className="btn btn-success btn-md text-white "
                onClick={handlePrint}
            >
                Print Slip
            </button>
        </div>
    );
}

export default SummaryPage;