import React from "react";
import {Box, Card, CardContent, Container, Typography} from "@mui/material";
import PersonalInfo from "./components/personal-info";
import JambInformation from "./components/jamb-info";
import SsceResultAndFiles from "./components/ssce-info";

export default function ApplicationForm() {
    const [page, setPage] = React.useState(1),
        [applicantData, setApplicantData] = React.useState({});

    const handlePersonalDetailsSubmit = (data) => {
        let newObj = {...applicantData, ...data};
        setApplicantData(newObj);
        setPage(2);
    };


    const handleJambDataSubmit = (data) => {
        let newObj = {...applicantData, ...data};
        setApplicantData(newObj);
        setPage(3);
    };

    React.useEffect(() => {
    }, [page]);

    return (
        <Container sx={{my: {xs: 0.3, lg: 3}}}>
            <Box sx={{justify: "center", textAlign: "center"}} component="div">
                <Typography variant="h4" sx={{fontWeight: 'bold', mt: {xs: 1, lg: 3}}}>
                    Undergraduate Admission Form
                </Typography>
            </Box>
            <Card sx={{px: {xs: 0, lg: 2.5}, mt: {xs: 1.2, lg: 2}}}>
                <CardContent>
                    <Box className="text-center">
                        <Typography variant="h6" className="text-danger link-underline-danger mb-sm-2 mb-lg-3">Important Notice</Typography>
                    </Box>
                    <Typography variant="body2">
                        Only candidates who scored above <span className="fw-bold">160</span> in UTME, or have applied for JUPEB for the 2023/2024 Academic Session are eligible to apply.
                        Admission Form of <span className="fw-bold">₦3,000 (three thousand Naira only)</span> for candidates with one sitting and <span className="fw-bold">₦5,000 (five thousand Naira only)</span> for candidates with
                        two sittings should be transferred to <span className="text-danger">Bank:</span> <span className="fw-bold">Access Bank, </span> <span className="text-danger">Account Name:</span> <span className="fw-bold">Abdulkadir Kure University, Minna, </span>
                        <span className="text-danger">Account Number:</span> <span className="fw-bold">1857122927</span>
                        <span className="mt-1 d-block">For issues and further information, contact this number <span className="fw-bold">07062977616</span></span>
                    </Typography>
                </CardContent>
            </Card>

            <Box sx={{display: page===1? 'block' : 'none'}}>
                <PersonalInfo onSubmit={(data) => handlePersonalDetailsSubmit(data)}/>
            </Box>

            <Box sx={{display: page===2? 'block' : 'none'}}>
                <JambInformation onForwardClick={(data) => handleJambDataSubmit(data)} onPreviousClick={() => setPage(1)}/>
            </Box>

            <Box sx={{display: page===3? 'block' : 'none'}}>
                <SsceResultAndFiles formInputs={applicantData} onPreviousClick={() => setPage(2)}/>
            </Box>
        </Container>
    )
}