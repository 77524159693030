import {createSlice} from '@reduxjs/toolkit'
const initialState = {

    jamb_number: '',
    jamb_total_score: '',
    jamb_subject_scores: [],
    jamb_first_choice: {},
    jamb_second_choice: {},
    applied_first_choice: {},
    applied_second_choice: {}
}

const jambSlice = createSlice({
    name: 'jambSlice',
    initialState,
    reducers: {
        addJambNumber: (state, action) => {
            state.jamb_number = action.payload
        },
        addTotalScore: (state, action) => {
            state.jamb_total_score = action.payload
        },
        addJambSubjectScore: (state, action) => {
            state.jamb_subject_scores = action.payload
        },
        addJambFirstChoice: (state, action) => {
            state.jamb_first_choice = action.payload
        },
        addJambSecondChoice: (state, action) => {
            state.jamb_second_choice = action.payload
        },
        appliedFirstJoice: (state, action) => {
            state.applied_first_choice = action.payload
        },
        appliedSecondJoice: (state, action) => {
            state.applied_second_choice = action.payload
        }
    }

})

export const {
    addJambNumber,
    addJambSecondChoice,
    addJambFirstChoice,
    addJambSubjectScore,
    addTotalScore,
    appliedFirstJoice,
    appliedSecondJoice
} = jambSlice.actions

export default jambSlice.reducer;







