import React from "react";
import {useRoutes} from "react-router-dom";
import ApplicantList from "../views/appicants";
import ApplicantPage from "../views/applicant";
import ApplicationForm from "../views/applicationForm";
import SummaryPage from "../views/components/SummaryPage";
import LoginPage from "../views/components/LoginPage";
import DashboardComponent from '../views/dashboard/Dashboard'

export default function Routes() {
    return useRoutes([
        {
            path: '/',
            element: <ApplicationForm/>
        },
        {
            path: '/applicants',
            element: <ApplicantList/>
        },
        {
            path: '/applicants/:id',
            element: <ApplicantPage/>
        },
        {
            path:'/application-summary',
            element: <SummaryPage/>
        },
        {
            path: '/login',
            element: <LoginPage/>
        },
        {
         path: '/dashboard/*',
         element: <DashboardComponent/>
        }
    ]);
}